import {makeAutoObservable} from "mobx";

export class TmpStore {
  // 用于区域管理员menu重新获取数据
  MenuReload = new Date().getTime();
  uKeyReturnCount = 0;
  constructor() {
    makeAutoObservable(this);
  }

  setMenuReload = () => {
    this.MenuReload = new Date().getTime();
    console.log(this.MenuReload);
  };

  setUKeyReturnCount = (value: number) => {
    this.uKeyReturnCount = value;
  };

  addUKeyReturnCount = () => {
    this.uKeyReturnCount++;
  };
  decUKeyReturnCount = () => {
    this.uKeyReturnCount--;
  };
}
