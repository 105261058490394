import React, {lazy, Suspense} from "react";
import {Spin} from "antd";
import type {RouteListType} from "./RoutesType";
import NotFoundPage from "../Components/Common/Error";


const AreaTree = lazy(() => import("../Components/Admin/AreaTree/AreaTree"));
const Wellcome = lazy(() => import("../Components/Admin/Content/Wellcome"));
// 管理员使用的页面
const AddUnit = lazy(() => import("../Components/Admin/UnitOperation/AddUnit"));
const EditUnit = lazy(
    () => import("../Components/Admin/UnitOperation/SearchAndEditUnit")
);
// 区域管理员使用的页面
const InitReviewed = lazy(() => import("../Components/Admin/InitReviewed/InitReviewed/InitReviewed"));
const DistributionUKey = lazy(
    () => import("../Components/Admin/InitReviewed/DistributionUKey/DistributionUKey")
);
const PersonalUkeyAndCode = lazy(
    () =>
        import(
            "../Components/Admin/InitReviewed/personalUkeyAndCode/personalUkeyAndCode"
            )
);
const BackOfficeProcessing = lazy(
    () => import("../Components/Admin/InitReviewed/BackOfficeProcessing/BackOfficeProcessing")
);
const SearchAll = lazy(
    () => import("../Components/Admin/InitReviewed/searchAll/searchAll")
);
const UnitInfoChangeReviewed = lazy(
    () =>
        import("../Components/Admin/UnitInfoChangeReviewed/UnitInfoChangeReviewed")
);
const UnitCancelReviewed = lazy(
    () => import("../Components/Admin/UnitCancelReviewed/UnitCancelReviewed")
);
const MemberAddReviewed = lazy(
    () =>
        import("../Components/Admin/MemberManager/addReviewed/MemberAddReviewed")
);
const DelMemberReviewed = lazy(
    () =>
        import("../Components/Admin/MemberManager/delReviewed/DelMemberReviewed")
);

const ShowUnitInitInfoNewPage = lazy(
    () => import("../Components/Admin/UnitOperation/showUnitInitInfoNewPage")
);
const MemberChangeReviewed = lazy(
    () => import("../Components/Admin/MemberChangeReviewed/MemberChangeReviewed")
);

const UKeyReviewed = lazy(
    () => import("../Components/Admin/UKeyManager/UKeyReviewed")
);

const HistoryCenter = lazy(
    () => import("../Components/Common/HistoryCenter/HistoryCenter")
);

const NotUsedUnitTable = lazy(() => import("../Components/Admin/InitReviewed/notUsedUnitTable/NotUsedUnitTable"))

// 区域管理员审核预决算公开计划页面
const ApprovalListTable = lazy(() => import("../Components/Admin/BudgetAndFinalAccountDisclosure/ReviewApproval/ApprovalListTable"))

// 预决算制定计划页面
const PlanTab = lazy(() => import("../Components/Admin/BudgetAndFinalAccountDisclosure/MakePlan/PlanTab"))
// 区域管理员审核单位采购项目页面
const GovernmentProcurementReviewed = lazy(() => import("../Components/Admin/GovernmentProcurement/GovernmentProcurementList"));

// 上传整改文件
const ErrorCorrectionPassing = lazy(
    () => import("../Components/OAID/ErrorCorrectionPassing/ErrorCorrectionPassing")
);

// 区域管理员查看合同台账
// const AdminContractLedgerTable = lazy(() =>
//     import("../Components/Admin/ContractLedgerTable/AdminContractLedgerTable")
// );

// 系统管理员新建操作账户
const NewAccount = lazy(() =>
    import("../Components/sysadmin/NewAccount/NewAccount")
);

// 系统管理员查看账户列表
const AdminAccountTable = lazy(() =>
    import("../Components/sysadmin/AdminAccountTable/AdminAccountTable")
);
// 系统管理员获取vpn配置列表
const VPNTable = lazy(() =>
    import("../Components/sysadmin/VPNTable/VPNTable")
);
//系统管理员获取单位签约信息
const UnitSignManagement = lazy(() =>
    import("../Components/sysadmin/unitSignManagement/unitSignManagement")
);

// 用于生成管理员页面的二级路由
export const AdminRoutes: RouteListType = [
    {
        auth: true,
        level: ["系统管理员"],
        route: {
            path: "sysadmin-area",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AreaTree key={"sysadmin-area"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["系统管理员"],
        route: {
            path: "",
            element: (
                <Suspense fallback={<Spin/>}>
                    <Wellcome key={"sysadmin-Wellcome"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["系统管理员"],
        route: {
            path: "sysadmin-new-account",
            element: (
                <Suspense fallback={<Spin/>}>
                    <NewAccount key={"sysadmin-new-account"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["系统管理员"],
        route: {
            path: "admin-account-management",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AdminAccountTable key={"admin-account-management"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["系统管理员"],
        route: {
            path: "sysadmin-vpn",
            element: (
                <Suspense fallback={<Spin/>}>
                    <VPNTable key={"sysadmin-vpn"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["系统管理员"],
        route: {
            path: "sysadmin-sign-management",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UnitSignManagement key={"sysadmin-sign-management"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: [ "区域管理员"],
        right:"预算单位管理",
        route: {
            path: "sysadmin-unit-add",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AddUnit key={"sysadmin-unit-add"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "sysadmin-unit-edit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <EditUnit key={"sysadmin-unit-edit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "sysadmin-people-management",
            element: (
                <Suspense fallback={<Spin/>}>
                    <EditUnit key={"sysadmin-unit-edit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "init-registration-audit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <InitReviewed key={"init-registration-audit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "not-used-unit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <NotUsedUnitTable key={"not-used-unit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "init-distribute-ukey",
            element: (
                <Suspense fallback={<Spin/>}>
                    <DistributionUKey key={"init-distribute-ukey"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "init-personal-ukey-code",
            element: (
                <Suspense fallback={<Spin/>}>
                    <PersonalUkeyAndCode key={"init-personal-ukey-code"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "init-back-office-processing",
            element: (
                <Suspense fallback={<Spin/>}>
                    <BackOfficeProcessing key={"init-back-office-processing"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "init-search-all",
            element: (
                <Suspense fallback={<Spin/>}>
                    <SearchAll key={"init-search-all"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "unit-change-audit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UnitInfoChangeReviewed key={"unit-change-audit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "unit-cancel-audit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UnitCancelReviewed key={"unit-cancel-audit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "sysadmin-people-add-audit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <MemberAddReviewed key={"sysadmin-people-add-audit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "sysadmin-people-decrease-audit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <DelMemberReviewed key={"sysadmin-people-decrease-audit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "sysadmin-show-unit-init-info",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ShowUnitInitInfoNewPage key={"sysadmin-show-unit-init-info"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "member-change-audit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <MemberChangeReviewed key={"member-change-audit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "ukey-change-audit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UKeyReviewed key={"ukey-change-audit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "history-center/:code",
            element: (
                <Suspense fallback={<Spin/>}>
                    <HistoryCenter key={"history-center"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预算单位管理",
        route: {
            path: "history-center",
            element: (
                <Suspense fallback={<Spin/>}>
                    <HistoryCenter key={"history-center"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"all",
        route: {
            path: "budget-and-final-account-disclosure-plan",
            element: (
                <Suspense fallback={<Spin/>}>
                    <PlanTab key={"budget-and-final-account-disclosure-plan"}/>
                </Suspense>)
        }
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预决算管理",
        route: {
            path: "budget-disclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ApprovalListTable budgetOrFinalAccount="budget" key={"budget-disclosure"}/>
                </Suspense>)
        }
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"预决算管理",
        route: {
            path: "final-account-disclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ApprovalListTable budgetOrFinalAccount="finalAccount" key={"final-account-disclosure"}/>
                </Suspense>)
        }
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"all",
        route: {
            path: "receive-corrective-action-documents-budget-disclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ErrorCorrectionPassing key={"error-correction-passing-budget"} budgetOrFinalAccount="budget"
                                            whoUse="areaAdmin"/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"all",
        route: {
            path: "receive-corrective-action-documents-final-account-disclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ErrorCorrectionPassing key={"error-correction-passing-finalAccount"}
                                            budgetOrFinalAccount="finalAccount" whoUse="areaAdmin"/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["区域管理员"],
        right:"政府采购管理",
        route: {
            path: "government-procurement-reviewed",
            element: (
                <Suspense fallback={<Spin/>}>
                    <GovernmentProcurementReviewed key={"government-procurement-reviewed"}/>
                </Suspense>
            ),
        }
    },
    // {
    //     auth: true,
    //     level: ["区域管理员"],
    //     route: {
    //         path: "contract-ledger",
    //         element: (
    //             <Suspense fallback={<Spin/>}>
    //                 <AdminContractLedgerTable key={"contract-ledger"}/>
    //             </Suspense>
    //         ),
    //     }
    // },
    {
        auth: false,
        level: [],
        route: {
            path: "*",
            element: <NotFoundPage key={"error_404"}/>,
        },
    },
];