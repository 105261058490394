import {makeAutoObservable} from "mobx";

type illustrateType = {
    areaId: number
    tel: string
    other: string
}

export class IllustrateStore {
    // 用于区域管理员menu重新获取数据
    illustrateList = [] as Array<illustrateType>;

    constructor() {
        makeAutoObservable(this);
    }

    setIllustrateList = (newList:Array<illustrateType>) => {
        this.illustrateList = newList;
    };

    getIllustrate (areaId:number):illustrateType|undefined  {
        return  this.illustrateList.find((item) => item.areaId === areaId);
    }

}