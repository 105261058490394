import type {RouteListType} from "./RoutesType";
import React, {lazy, Suspense} from "react";
import {Spin} from "antd";
import NotFoundPage from "../Components/Common/Error";


const HistoryCenter = lazy(
    () => import("../Components/Common/HistoryCenter/HistoryCenter")
);


// 单位使用的页面

const UnitFirstIn = lazy(() => import("../Components/unit/FirstIn/FirstIn"));
const UnitInitRes = lazy(() => import("../Components/unit/FirstIn/res"));
const ShowUnitUser = lazy(() => import("../Components/unit/showUnitUser"));
const UnitInfoChange = lazy(
    () => import("../Components/unit/UnitInfoChange/UnitInfoChange")
);
const UnitInfoCancel = lazy(
    () => import("../Components/unit/UnitInfoChange/UnitInfoCancel")
);
const MemberAdd = lazy(
    () => import("../Components/unit/MemberAddOrDel/MemberAdd/MemberAdd")
);
const MemberDel = lazy(
    () => import("../Components/unit/MemberAddOrDel/MemberDel/MemberDel")
);
const GetNewUkey = lazy(
    () => import("../Components/unit/GetNewUkey/GetNewUkey")
);
const MemberChange = lazy(
    () => import("../Components/unit/MemberChange/MemberChange")
);
// 单位端预决算公开页面
const UnitBudgetAndFinalAccountDisclosure = lazy(
    () => import("../Components/unit/BudgetAndFinalAccountDisclosure/BudgetAndFinalAccountDisclosure")
);

// 单位端政府采购项目页面
const NewProcurement = lazy(() => import("../Components/unit/GovernmentProcurement/step1to4dot1/newProcurement/newProcurement"));
const ProcurementList = lazy(() => import("../Components/unit/GovernmentProcurement/procurementList"));


//合同台账
const ContractLedger = lazy(() => import("../Components/unit/ContractLedger/ContractLedgerTable"));

//教程下载
const HelpBook = lazy(() => import("../Components/Common/helpbook/helpbook"));


// 单位用户路由
export const UnitRoutes: RouteListType = [
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "unit-init",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UnitFirstIn key={"unit-init"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "unit-init-user",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ShowUnitUser key={"unit-init-user"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "unit-info-change",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UnitInfoChange key={"unit-info-change"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "unit-init-res",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UnitInitRes key={"unit-init-res"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "unit-info-cancel",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UnitInfoCancel key={"unit-info-cancel"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "unit-member-add",
            element: (
                <Suspense fallback={<Spin/>}>
                    <MemberAdd key={"unit-member-add"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "unit-member-del",
            element: (
                <Suspense fallback={<Spin/>}>
                    <MemberDel key={"unit-member-del"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "get-new-ukey",
            element: (
                <Suspense fallback={<Spin/>}>
                    <GetNewUkey key={"get-new-ukey"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "unit-member-change",
            element: (
                <Suspense fallback={<Spin/>}>
                    <MemberChange key={"unit-member-change"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "history-center",
            element: (
                <Suspense fallback={<Spin/>}>
                    <HistoryCenter key={"history-center"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "budget-disclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UnitBudgetAndFinalAccountDisclosure budgetOrFinalAccount="budget" key={"budget-disclosure"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "final-account-disclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <UnitBudgetAndFinalAccountDisclosure budgetOrFinalAccount="finalAccount" key={"budget-disclosure"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "new-procurement",
            element: (
                <Suspense fallback={<Spin/>}>
                    <NewProcurement key={"new-procurement"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "procurement-list",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ProcurementList key={"procurement-list"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "contract-ledger",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ContractLedger key={"contract-ledger"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "help-book",
            element: (
                <Suspense fallback={<Spin/>}>
                    <HelpBook key={"help-book"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: false,
        level: [],
        route: {
            path: "*",
            element: <NotFoundPage key={"error_404"}/>,
        },
    },
];