import type {RouteListType} from "./RoutesType";
import React, {lazy, Suspense} from "react";
import {Spin} from "antd";
import NotFoundPage from "../Components/Common/Error";

// 区域管理员本单位使用的页面
const AdminUnitInfo = lazy(() => import("../Components/AdminUnit/UnitInfo/AdminUnitInfo"));
const DepartmentEdit = lazy(() => import("../Components/AdminUnit/departmentEdit/departmentEdit"));
const AreaAdminHistoryCenter = lazy(() => import("../Components/AdminUnit/HistoryCenter/HistoryCenter"));
const AreaAdminUnitAddMember = lazy(() => import("../Components/AdminUnit/MemberAdd/MemberAdd"));
const AreaAdminUnitChangeMember = lazy(() => import("../Components/AdminUnit/MemberChange/MemberChange"));
const AreaAdminUnitDelMember = lazy(() => import("../Components/AdminUnit/MemberDel/MemberDel"));

// 用于区域管理员管理本单位的路由
export const AdminUnitRoutes: RouteListType = [
    {
        auth: true,
        level: ["区域管理员"],
        route: {
            path: "admin-unit-init",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AdminUnitInfo key={"admin-unit-init"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        route: {
            path: "unit-department-edit",
            element: (
                <Suspense fallback={<Spin/>}>
                    <DepartmentEdit key={"unit-department-edit"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["区域管理员"],
        route: {
            path: "unit-member-add",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AreaAdminUnitAddMember key={"unit-member-add"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["区域管理员"],
        route: {
            path: "history-center",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AreaAdminHistoryCenter key={"history-center"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["区域管理员"],
        route: {
            path: "unit-member-change",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AreaAdminUnitChangeMember key={"unit-member-change"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["区域管理员"],
        route: {
            path: "unit-member-del",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AreaAdminUnitDelMember key={"unit-member-del"}/>
                </Suspense>
            ),
        }
    },
    {
        auth: false,
        level: [],
        route: {
            path: "*",
            element: <NotFoundPage key={"error_404"}/>,
        },
    },
]