import type {RouteListType} from "./RoutesType";
import React, {lazy, Suspense} from "react";
import {Spin} from "antd";
import NotFoundPage from "../Components/Common/Error";

// 监督检查部门

// 上传整改文件
const ErrorCorrectionPassing = lazy(
    () => import("../Components/OAID/ErrorCorrectionPassing/ErrorCorrectionPassing")
);
const ReceiveFeedback = lazy(
    () => import("../Components/OAID/ReceiveFeedbackFiles/ReceiveFeedbackFiles")
);


// 用于监督检查部门的路由
export const OAIDRoutes: RouteListType = [
    {
        auth: true,
        level: ["监督检查部门"],
        route: {
            path: "ErrorCorrectionPassing-BudgetDisclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ErrorCorrectionPassing key={"error-correction-passing-budget"} budgetOrFinalAccount="budget"/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["监督检查部门"],
        route: {
            path: "ErrorCorrectionPassing-FinalAccountDisclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ErrorCorrectionPassing key={"error-correction-passing-finalAccount"}
                                            budgetOrFinalAccount="finalAccount"/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["监督检查部门"],
        route: {
            path: "ReceiveFeedback-BudgetDisclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ReceiveFeedback key={"receive-feedback-budget"} budgetOrFinalAccount="budget"/>
                </Suspense>
            ),
        }
    },
    {
        auth: true,
        level: ["监督检查部门"],
        route: {
            path: "ReceiveFeedback-FinalAccountDisclosure",
            element: (
                <Suspense fallback={<Spin/>}>
                    <ReceiveFeedback key={"receive-feedback-finalAccount"} budgetOrFinalAccount="finalAccount"/>
                </Suspense>
            ),
        }
    },
    {
        auth: false,
        level: [],
        route: {
            path: "*",
            element: <NotFoundPage key={"error_404"}/>,
        },
    },
];