import React from "react";
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";
import useStore from "../../Store/useStore";
import {observer} from "mobx-react";

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    const loginstore = useStore("loginstore");
    const illustrateStore = useStore("illustrateStore");

    if (loginstore.areaId) {
        const li = illustrateStore.getIllustrate(loginstore.areaId);
        if (li) {
            return <Result
                status="404"
                title="没有权限"
                subTitle={li.other + li.tel}
                extra={
                    <Button type="primary" onClick={() => navigate("/")}>
                        返回
                    </Button>
                }
            />
        }
    }
    return <Result
        status="404"
        title="没有权限"
        subTitle="抱歉, 您无权访问此页面，若存在疑问请联系京科立讯软件技术（云南）有限公司"
        extra={
            <Button type="primary" onClick={() => navigate("/")}>
                返回
            </Button>
        }
    />

};

export default observer(NotFoundPage);
