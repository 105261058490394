import {FC, useEffect} from "react";
import "./App.css";
import RouteList from "./router/RoterList";
import useStore from "./Store/useStore";
import {observer} from "mobx-react";
import {get} from "./Utils/http"

const App: FC = () => {
    const loginstore = useStore("loginstore");
    const illustrateStore = useStore("illustrateStore");
    useEffect(() => {
        const getTel = async () => {
            const timeFlag = new Date().getTime();
            try {
                const res = await get("/getIllustrateList", {t: timeFlag})
                if (res && res.illustrate) {
                    illustrateStore.setIllustrateList(res.illustrate)
                }
            } catch (e) {
                console.log(e)
            }
        }
        if (illustrateStore.illustrateList.length === 0) {
            getTel()
        }
    }, [illustrateStore, loginstore.isLogin]);
    return RouteList("main");
};

export default observer(App);
