import type {RouteListType} from "./RoutesType";
import React, {lazy, Suspense} from "react";
import {Spin} from "antd";
import NotFoundPage from "../Components/Common/Error";

const Login = lazy(() => import("../Components/Login/Login"));
const Admin = lazy(() => import("../Components/Admin/Admin"));
const AuthRouter = lazy(() => import("./AuthRouter"));
const Unit = lazy(() => import("../Components/unit/Unit"));
const AdminUnit = lazy(() => import("../Components/AdminUnit/AdminUnit"));
const OAID = lazy(() => import("../Components/OAID/index"));
// 用于生成路由
export const MainRoutes: RouteListType = [
    {
        auth: false,
        level: [""],
        route: {
            path: "/",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AuthRouter key={"AuthRouter"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: false,
        level: [""],
        route: {
            path: "/login",
            element: <Login key={"login"}/>,
        },
    },
    {
        auth: true,
        level: ["系统管理员", "区域管理员"],
        route: {
            path: "/admin/*",
            element: (
                <Suspense fallback={<Spin/>}>
                    <Admin key={"admin"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: true,
        level: ["区域管理员"],
        route: {
            path: "/adminUnit/*",
            element: (
                <Suspense fallback={<Spin/>}>
                    <AdminUnit key={"adminUnit"}/>
                </Suspense>
            )
        }
    },
    {
        auth: true,
        level: ["单位管理员"],
        route: {
            path: "/unit/*",
            element: (
                <Suspense fallback={<Spin/>}>
                    <Unit key={"unit"}/>
                </Suspense>
            ),
        },
    },
    {
        auth: false,
        level: ["监督检查部门"],
        route: {
            path: "/OAID/*",
            element: <Suspense fallback={<Spin/>}>
                <OAID key={"OAID"}/>
            </Suspense>,
        },
    },
    {
        auth: false,
        level: [],
        route: {
            path: "*",
            element: <NotFoundPage key={"error_404"}/>,
        },
    },
];
