import {makeAutoObservable} from "mobx";

interface loginObj {
    isLogin: boolean;
    token: string;
    id: number;
    code: string;
    idNumber: string;
    name: string;
    level: string;
    unitStatus: number;
    areaTreeLable: string[];
    areaIds: AreaID[];
    rights: string[];
    areaId: number;
}

export type AreaID = {
    label: string;
    value: string;
}

const DATAKEY = "loginstore-store-data";
const Storage = window.localStorage;

export class LoginStore {
    isLogin = false;
    token = "123456";
    id = 0;
    code = "";
    idNumber = "";
    name = "张三三";
    level = "";
    unitStatus = 0;
    baseUrl = "";
    areaTreeLable = [] as Array<string>;
    areaIds = [] as Array<AreaID>;
    rights = [] as Array<string>;
    areaId = 0;

    constructor() {
        this.baseUrl = this.getBaseURL();
        const dataStr = Storage.getItem(DATAKEY);
        if (dataStr) {
            let data = JSON.parse(dataStr);
            this.setLogin(data);
        }
        makeAutoObservable(this);
        // this.autoRun();
    }


    setLogin = (obj: loginObj) => {
        // console.log(obj);
        this.isLogin = true;
        this.level = obj.level;
        this.name = obj.name;
        this.token = obj.token;
        this.id = obj.id;
        this.code = obj.code;
        this.idNumber = obj.idNumber;
        this.areaTreeLable = obj.areaTreeLable;
        this.unitStatus = obj.unitStatus;
        this.areaId = obj.areaId;
        if (obj.code.includes("@")) {
            this.areaIds = obj.areaIds;
            this.rights = obj.rights;
        }
        const dataStr = JSON.stringify(this);
        Storage.setItem(DATAKEY, dataStr);
    };
    setLogout = () => {
        this.isLogin = false;
        this.level = "";
        this.name = "";
        this.token = "";
        this.id = 0;
        this.code = "";
        this.idNumber = "";
        this.areaTreeLable = [];
        this.unitStatus = 0;
        this.areaIds = [];
        this.areaId = 0;
        Storage.removeItem(DATAKEY);
    };

    setStatus = (state: number) => {
        console.log(state);
        this.unitStatus = state;
        const dataStr = JSON.stringify(this);
        // console.log(this);
        // console.log(dataStr);
        Storage.setItem(DATAKEY, dataStr);
        // console.log(Storage.getItem(DATAKEY));
    };

    setStatusAndIdNumberAndName = (
        state: number,
        idNumber: string,
        name: string
    ) => {
        console.log(state);
        this.unitStatus = state;
        this.idNumber = idNumber;
        this.name = name;
        const dataStr = JSON.stringify(this);
        Storage.setItem(DATAKEY, dataStr);
    };

    get IsUnitInit() {
        const {unitStatus, idNumber, name} = this;
        return unitStatus === 0 || idNumber === "" || name === "";
    }

    hasRight = (right: string | undefined): boolean => {
        if (this.level !== "区域管理员") {
            return true;
        }
        if (!this.code.includes("@")) {
            return true;
        }
        if (!right) {
            return true;
        }
        if (!this.rights || this.rights.length === 0) {
            return true;
        }
        if (this.rights.includes("all")) {
            return true;
        }
        return this.rights.includes(right);
    }


    getBaseURL = () => {
        const hostname = window.location.hostname;

        const isLocalhost = hostname === 'localhost' || hostname === '127.0.0.1';
        const port = window.location.port;

        // 本地开发环境
        if (isLocalhost && port === "3000") {
            return `http://${hostname}:8010`;
        }

        const ipParts = hostname.split('.').map(part => parseInt(part, 10));

        //局域网开发环境
        if (ipParts.length === 4) {
            const isIntranet = (ipParts[0] === 10) ||
                (ipParts[0] === 172 && ipParts[1] >= 16 && ipParts[1] <= 31) ||
                (ipParts[0] === 192 && ipParts[1] === 168);
            if (isIntranet) {
                return `http://${hostname}:8010`;
            }
        }

        // 本地代理环境
        if (isLocalhost && Number(port) > 3000) {

            return `http://${hostname}:${port}/api`;
        }

        //正式服务器环境
        return `https://${hostname}/api`;
    }

}
