import React from "react";
import useStore from "../Store/useStore";
import {RouteObject, useRoutes} from "react-router-dom";
import {AdminRoutes} from "./AdminRoutes";
import {MainRoutes} from "./MainRoutes";
import {UnitRoutes} from "./UnitRoutes";
import {AdminUnitRoutes} from "./AdminUnitRoutes";
import {OAIDRoutes} from "./OAIDRoutes";
import {RouteListType} from "./RoutesType";

// 遍历路由列表，生成符合条件的路由
const RouteList = (which: string): React.ReactElement | null => {
    const {isLogin, level, code, rights, hasRight} = useStore("loginstore");
    let tmp: RouteObject[] = [];
    let routes: RouteListType = [];
    if (which === "main") {
        routes = MainRoutes;
    } else if (which === "admin") {
        routes = AdminRoutes
    } else if (which === "unit") {
        routes = UnitRoutes;
    } else if (which === "admin-unit") {
        console.log("admin-unit");
        routes = AdminUnitRoutes;
    } else if (which === "OAID") {
        routes = OAIDRoutes;
        console.log("OAID");
    }

    for (let item of routes) {
        // 无需登录的路由
        if (!item.auth) tmp.push(item.route);
        // 需要登录，且已登录，且权限符合的路由
        if (item.auth && isLogin && item.level.includes(level) && hasRight(item.right)) {
            tmp.push(item.route);
        }
    }
    // console.log(tmp);
    return useRoutes(tmp);
};

export default RouteList;

