/**
 * 网络请求配置
 */
import axios from "axios";
import {RootStore} from "../Store";
import {message} from "antd";

axios.defaults.timeout = 100000;

axios.defaults.baseURL = RootStore.loginstore.baseUrl;

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
    (config) => {
        config.data = JSON.stringify(config.data);
        let tmp = config.headers
        tmp["Content-Type"] = "application/json;charset=UTF-8";
        tmp["authorization"] = RootStore.loginstore.token;
        config.headers = tmp;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

let LoginExpired = false;

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
    (response) => {
        if (response.data.errCode === 2) {
            console.log("过期");
        }
        if(response.status === 200 ){
            LoginExpired = false;
        }
        return response;
    },
    (error) => {
        console.log("请求出错:", error);
        console.log(error.response.status, error.response.data.Code);
        const code = error.response.data.Code;
        if (error.response.status === 401 && (code === 2003 || code === 2004 || code === 2005 || code === undefined)) {
            if (!LoginExpired) {
                LoginExpired = true;
                message.error("登录过期，请重新登录").then(
                    () => {
                        RootStore.loginstore.setLogout();
                        window.location.href = "/login";
                    }
                );
            }
        }
        return Promise.reject(error);
    }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url: string, params = {}): Promise<any> {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
            })
            .then((response) => {
                //  landing(url, params, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function download(url: string, params = {},): Promise<any> {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: {...params,flg:Date.now().toString()},
                responseType: "blob",
            })
            .then((response) => {
                //  landing(url, params, response.data);
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function downloadByPost(url: string, data = {},): Promise<any> {
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                responseType: "blob",
            })
            .then((response) => {
                //  landing(url, data, response.data);
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                //关闭进度条
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url: string, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                msag(err);
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url: string, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                // msag(err);
                reject(err);
            }
        );
    });
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del(url: string, data = {}): Promise<any> {
    return new Promise((resolve, reject) => {
        axios.delete(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                // msag(err);
                reject(err);
            }
        );
    });
}

//统一接口处理，返回数据
export function http(fecth: string, url: string, param: any): Promise<any> {
    return new Promise((resolve, reject) => {
        switch (fecth) {
            case "get":
                console.log("begin a get request,and url:", url);
                get(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log("get request GET failed.", error);
                        msag(error);
                        reject(error);
                    });
                break;
            case "post":
                post(url, param)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log("get request POST failed.", error);
                        msag(error);
                        reject(error);
                    });
                break;
            default:
                break;
        }
    });
}

//失败提示
function msag(err: any) {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                message.error(err.response.data.error.details);
                break;
            case 401:
                message.error("未授权,请登录");
                RootStore.loginstore.setLogout();
                break;

            case 403:
                message.error("拒绝访问");
                break;

            case 404:
                message.error("请求地址出错");
                break;

            case 408:
                message.error("请求超时");
                break;

            case 500:
                message.error("服务器内部错误");
                break;

            case 501:
                message.error("服务未实现");
                break;

            case 502:
                message.error("网关错误");
                break;

            case 503:
                message.error("服务不可用");
                break;

            case 504:
                message.error("网关超时");
                break;

            case 505:
                message.error("HTTP版本不受支持");
                break;
            default:
        }
    }
}
